<template>
  <!-- 组件（打样需求详情用到） -->
  <div v-if="item && item.id">
    <div class="zt-block" style="min-width:950px">
      <div style="padding:20px 30px;">
        <h3 style="font-weight:600;margin-bottom:0;" class="font14-grey ellipsis">{{item.title}}</h3>
      </div>
    </div>
    <div class="zt-block" style="min-width:950px">
      <div class="zt-block-head">商品打样信息</div>
      <div v-for="(good,idx) in item[item.type].items" :key="idx">
        <div v-if="falgItem ? idx>=0 : idx==0" class="item">
          <div>商品{{idx+1}}</div>
          <el-row type="flex">
            <div class="item-info-category">
              <div>类目</div>
              <div v-if="good.cat" class="value">{{good.cat.name}}</div>
            </div>
            <div class="item-info-category">
              <div>风格</div>
              <div v-if="good.style" class="value">{{good.style.name}}</div>
            </div>
            <div class="item-info-category">
              <div>数量</div>
              <div v-if="good.count" class="value">{{good.count}}个</div>
            </div>
          </el-row>
          <div class="flex">
            <div class="category-title">渲染图</div>
            <div v-if="good.renderImgs.length>0" class="img_group">
              <div
                v-for="(img,index) in good.renderImgs"
                :key="index"
                class="bg_img"
                :style="{backgroundImage: 'url(' + imgUrl+img+'!width_250px' + ')'}"
              ></div>
            </div>
          </div>
          <div v-if="good.sizeImgs.length>0" class="flex">
            <div class="category-title">尺寸图</div>
            <div class="img_group">
              <div
                v-for="(img,index) in good.sizeImgs"
                :key="index"
                class="bg_img"
                :style="{backgroundImage: 'url(' + imgUrl+img+'!width_250px' + ')'}"
              ></div>
            </div>
          </div>
          <div v-if="good.cadFiles.length>0" class="flex">
            <div class="category-title">CAD文件</div>
            <div class="flex-1 flex">
              <img style="width: 60px;" src="../../../assets/images/desgin/icon_cad.png" alt />
              <div
                v-if="good.cadFiles"
                style="padding-top:50px"
                class="font14-grey1"
              >{{good.cadFiles.length}}份</div>
              <div style="padding-top:35px;margin-left: 20px;">
                <a :href="imgUrl+good.cadFiles[0]" target="_bank">
                  <el-button size="small" type="primary" plain>下载</el-button>
                </a>
              </div>
            </div>
          </div>
          <div
            v-if="item[item.type] && item[item.type].items.length>1 && idx==0"
            class="flex flex-pack-center"
          >
            <div class="btn-falgDesc cus-text" @click="showDesc('falgItem')" style="width:90px">
              显示更多
              <i
                :class="{'active':falgItem}"
                class="falgDesc font14-grey1 iconfont icon-fanhuitubiao1"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="list-item flex">
        <div class="list-item-tr">
          <div class="font14-grey1">需求类型</div>
          <div v-if="item.type && item.type=='peitao'">加工需求</div>
          <div v-if="item.type && item.type=='gaikuang'">设计需求</div>
          <div v-if="item.type && item.type=='dayang'">打样</div>
        </div>
        <div class="list-item-tr" style="width:240px">
          <div>付款方式</div>
          <div v-if="item[item.type] && item[item.type].payType">
            <div>{{payTypeDesc}}</div>
          </div>
        </div>
        <div class="list-item-tr">
          <div>期望验收日期</div>
          <div v-if="item[item.type]">{{item[item.type].checkDate | moment("YYYY/MM/DD")}}</div>
        </div>
        <div class="list-item-tr">
          <div>验收方式</div>
          <div v-if="item[item.type] && item[item.type].checkType==1">样品寄送验收</div>
          <div v-if="item[item.type] && item[item.type].checkType==2">视频验收</div>
        </div>
        <!-- <div class="flex-1 flex flex-align-center">
          <div>
            <div
              v-if="item[item.type] && item[item.type].offers && item[item.type].offers.length>0"
            >
              <div style="color:#2BBAAE;margin:0 0 10px 18px;">已应答</div>
              <el-button @click="viewPipe()" size="mini" type="primary" round>查看应答</el-button>
            </div>
            <el-button
              v-if="item[item.type] && item[item.type].offers && item[item.type].offers.length==0"
              @click="popPipe()"
              size="mini"
              type="primary"
              round
            >我要应答</el-button>
          </div>
        </div>-->
      </div>
      <slot></slot>
      <div class="list-desc">
        <div class="flex-1">收货信息</div>
        <div class="flex">
          <div>收货人</div>
          <div v-if="item[item.type]">{{item[item.type].reciver}}</div>
        </div>
        <div class="flex">
          <div>寄送地址</div>
          <div v-if="item[item.type]">{{item[item.type].address}}</div>
        </div>
        <div class="flex">
          <div>联系方式</div>
          <div v-if="item[item.type]">{{item[item.type].contact}}</div>
        </div>
      </div>
    </div>
    <div class="zt-block" style="min-width:950px">
      <div class="zt-block-head flex">
        <div class="flex-1">工艺及需求说明</div>
        <div class="btn-falgDesc cus-text" @click="showDesc('falgDesc')">
          显示详情
          <i
            :class="{'active':falgDesc}"
            class="falgDesc font14-grey1 iconfont icon-fanhuitubiao1"
          ></i>
        </div>
      </div>
      <div v-if="falgDesc" class="list-gynl flex">
        <div style="width: 50%;" class="flex-1 flex">
          <div>需求描述</div>
          <div
            v-if="item[item.type] && item[item.type].desc"
            style="padding-right:50px;"
            class="font14-grey flex-1"
          >{{item[item.type].desc}}</div>
        </div>
        <div
          v-if="item[item.type] && item[item.type].gybz && item[item.type].gybz.length>0"
          style="width:400px"
          class="flex"
        >
          <el-table :data="item[item.type].gybz" stripe border style="width: 100%">
            <el-table-column prop="name" label="工艺要求" width="80"></el-table-column>
            <el-table-column prop="quota" label="参考值" max-width="260">
              <template slot-scope="scope">
                <div v-if="scope.row.quota && scope.row.quota.length>0" class="font14-grey1 flex">
                  <div v-for="(quota,idx) in scope.row.quota" :key="idx" class="flex">
                    <!-- <div>{{quota.name}}：</div>
                    <div>{{quota.value}}</div>-->
                    <div v-if="quota.type==1" class="flex" style="margin-right:10px">
                      <div>{{quota.name}}：</div>
                      <div v-for="(option,oi) in quota.options" :key="oi">
                        <div v-if="quota.value == option.k">{{option.v}}</div>
                      </div>
                    </div>
                    <div v-if="quota.type==2" class="flex">
                      <div>{{quota.name}}：</div>
                      <div v-if="quota.value && quota.value.length>0" class="flex">
                        <div v-for="(val,vi) in quota.value" :key="vi" class="flex">
                          <div v-if="vi>0&&vi<quota.options.length">，</div>
                          <div v-for="(option,oi) in quota.options" :key="oi">
                            <div v-if="val == option.k">{{option.v}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="quota.type!=1 && quota.type!=2"
                      class="flex"
                      style="margin-right:10px"
                    >
                      <div>{{quota.name}}：</div>
                      <div>{{quota.value}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE } from "@/config";
import { peitaoOfferAdd } from "@/service/want";
import { getPayType } from "@/service/pay";
export default {
  props: ["item", "answerItem"],
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popTitle: "",
      popVisible: false,
      rules: {
        price: [{ required: true, message: "请填写出价", trigger: "blur" }]
      },
      falgItem: false,
      falgDesc: false,
      ruleForm: {
        requirementId: this.$route.params.id,
        price: ""
      },
      flagPrice: true,
      payTypes: [],
      payTypeDesc: null
    };
  },
  async created() {
    this.payTypes = await getPayType();
    let obj = this.payTypes.find(e => {
      return e.code == this.item[this.item.type].payType;
    });
    if (obj) this.payTypeDesc = obj.desc;
    // console.log(this.item);
  },
  mounted() {},
  methods: {
    goto(link) {
      this.$router.push(link);
    },
    back() {
      this.$router.go(-1);
    },
    showDesc(type) {
      if (type == "falgDesc") {
        this.falgDesc = !this.falgDesc;
      } else if (type == "falgItem") {
        this.falgItem = !this.falgItem;
      }
    },
    anewPrice() {
      this.flagPrice = true;
    },
    popPipe() {
      this.popTitle = "填写应答信息";
      this.popVisible = true;
    },
    viewPipe() {
      this.popTitle = "查看应答信息";
      this.popVisible = true;
    },
    submitForm() {
      this.$refs["formCheck"].validate(valid => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit: _.debounce(function() {
      let _item = _.clone(this.ruleForm);
      console.log(_item);
      peitaoOfferAdd("dayang", _item)
        .then(rst => {
          this.$emit("onSuccess");
          this.$message.success("提交成功");
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }, 1000)
  }
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.zt-block {
  box-shadow: none;
}
.zt-block-head {
  font-size: 18px;
  font-weight: 400;
  color: #3b3b3b;
  padding: 15px 30px 15px 30px;
  line-height: 25px;
}
.item {
  padding: 10px 30px;
  font-size: 14px;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.item > div {
  font-size: 16px;
  color: #808080;
  line-height: 22px;
  margin-bottom: 10px;
}
.img_group > div {
  width: 120px;
  height: 120px;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  position: relative;
  left: -5px;
}
.list-item {
  padding: 10px 0;
  background: rgba(250, 250, 255, 1);
}
.list-item > div {
  min-width: 160px;
  min-height: 110px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
  position: relative;
}
.list-item > div:last-child {
  border-right: 0;
}
.list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.btn-falgDesc {
  width: 90px;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  cursor: pointer;
}
.falgDesc {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 1px;
  transform: rotate(90deg);
}
.falgDesc.active {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari 和 Chrome */
  -o-transform: rotate(270deg); /* Opera */
}
.list-desc {
  padding: 30px;
  color: #4d4d4d;
  border-top: 1px solid rgba(230, 230, 230, 1);
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.list-desc > div {
  margin-bottom: 20px;
}
.list-desc > div:last-child {
  margin-bottom: 0px;
}
.list-desc > div > div:first-child {
  font-size: 14px;
  color: #808080;
  line-height: 22px;
  margin-right: 10px;
}
.list-gynl {
  padding: 20px 30px;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.list-gynl > div > div:first-child {
  font-size: 14px;
  color: #808080;
  line-height: 22px;
  margin-right: 10px;
}
.list-gynl >>> .has-gutter tr th {
  background: #e6e6e6;
}
.list-gynl >>> .el-table__header-wrapper {
  width: 100%;
}
</style>
<style lang="less" scoped>
.item-info {
  &-category {
    min-width: 100px;
    margin-right: 10px;
    font-size: 14px;

    .label {
      color: #808080;
    }

    .value {
      color: #4d4d4d;
    }
  }
}

.category-title {
  margin-right: 10px;
}
</style>